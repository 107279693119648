import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/layouts/MdxLayout.js";
import SEO from "../../components/Seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Club Pricing – Wine and Cider Club by Archibald James" description="Wine and Cider Club by Archibald James" mdxType="SEO" />
    <article className="mx-auto prose prose-xl">
      <h1 {...{
        "id": "club-pricing"
      }}>{`Club Pricing`}</h1>
      <p>{`We curate each club package, and the total cost will depend on the selection.`}</p>
      <p>{`Shipping cost varies by destination.`}</p>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      